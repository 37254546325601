import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

import isValidEmailAddress from 'frontend-utils/utils/is-valid-email-address';
import type FetchService from 'password-form/services/fetch';
import type FirebaseService from 'password-form/services/firebase-service';

export default class PasswordResetController extends Controller {
  queryParams = ['email', 'success'];

  @service declare router: RouterService;

  @service declare fetch: FetchService;

  @service declare intl: IntlService;

  @service declare firebaseService: FirebaseService;

  @tracked email = '';

  @tracked isEmailValid = true;

  @tracked userHasErrors = false;

  @tracked success = false;

  validateEmail() {
    this.isEmailValid = isValidEmailAddress(this.email);
  }

  get pageTitle() {
    return this.intl.t('forgot_your_password.reset');
  }

  @action
  setEmail(event: Event) {
    this.email = (event.target as HTMLInputElement).value;
    this.isEmailValid = true;
  }

  @action
  goBack() {
    this.success = false;
    this.router.transitionTo('login');
  }

  @action
  submitFormWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.resetPassword();
    }
  }

  @action
  async resetPassword() {
    this.validateEmail();
    if (!this.isEmailValid) {
      return;
    }

    try {
      await this.fetch.post('/password-reset/', {}, { email: this.email }, 'form');

      // Clear firebase auth data
      await this.firebaseService.auth.signOut();
      await indexedDB.deleteDatabase('firebaseLocalStorageDb');

      // Construct URL with query params
      const url = this.router.urlFor('password-reset', {
        queryParams: {
          success: true,
          email: this.email,
        },
      });

      window.location.href = url;
    } catch (error) {
      this.isEmailValid = false;
    }
  }
}
