import Controller from '@ember/controller';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { modifier } from 'ember-modifier';
import { FirebaseError } from 'firebase/app';
import { RecaptchaVerifier, type PhoneMultiFactorInfo } from 'firebase/auth';

import type MfaRoute from 'frontend-login/routes/mfa';
import type { ModelFrom } from 'frontend-login/utils/model-from';
import type ErrorTrackingService from 'frontend-utils/services/error-tracking';
import { formatPhoneNumber } from 'frontend-utils/utils/phone-number-utils';
import type FirebaseService from 'password-form/services/firebase-service';
import type MfaService from 'password-form/services/mfa-service';
import nextWithDefault from 'password-form/utils/auth-utils';
import {
  FIREBASE_ERROR_CODES,
  MFA_PHONE_FACTOR_ID,
  MFA_TYPES,
  MFA_VERIFICATION_CODE_LENGTH,
  type MFAType,
} from 'password-form/utils/constant-values';
import NoActiveUserError from 'password-form/utils/no-active-user-error';

export default class MFAController extends Controller {
  queryParams = ['next'];

  @service declare mfaService: MfaService;

  @service declare firebaseService: FirebaseService;

  @service declare errorTracking: ErrorTrackingService;

  @service declare intl: IntlService;

  @tracked next: string | undefined;

  @tracked radioMfaType: MFAType = MFA_TYPES.MOBILE;

  @tracked phoneNumber = '';

  @tracked selectedCountryCode = {
    code: 'US',
    phoneCode: '1',
  };

  @tracked hasValidPhoneNumber = false;

  @tracked showEnrollmentVerificationStep = false;

  @tracked verificationCode = '';

  @tracked isVerificationCodeValid = true;

  @tracked showAuthenticatorCode = false;

  @tracked totpSecret = '';

  @tracked isContinueForVerificationLoading = false;

  @tracked isContinueForSelectionLoading = false;

  @tracked errorTitle = '';

  @tracked errorMessage = '';

  @tracked showError = false;

  @tracked captchaCode = '';

  @tracked loginCaptchaResolved = false;

  @tracked isCaptchaInVerificationCodeEnabled = false;

  declare model: ModelFrom<MfaRoute>;

  captchaElement: HTMLDivElement | null = null;

  captcha: RecaptchaVerifier | null = null;

  MFA_TYPES = MFA_TYPES;

  // Only used for testing purposes
  enableCaptcha = true;

  initializeRecaptcha = modifier((element: HTMLDivElement) => {
    this.createCaptcha(element);
  });

  cleanupCaptcha = () => {
    this.captchaElement = null;
    this.captcha = null;
  };

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);
    registerDestructor(this, this.cleanupCaptcha);
  }

  reset() {
    this.showEnrollmentVerificationStep = false;
    this.selectedCountryCode = {
      code: 'US',
      phoneCode: '1',
    };
    this.phoneNumber = '';
    this.verificationCode = '';
    this.showAuthenticatorCode = false;
    this.captchaCode = '';
  }

  async enrollEmail() {
    try {
      this.isContinueForSelectionLoading = true;
      await this.mfaService.startEnrollGip();
      this.showEnrollmentVerificationStep = true;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.isContinueForSelectionLoading = false;
    }
  }

  async enrollMobile() {
    try {
      this.isContinueForSelectionLoading = true;
      const totpSecret = await this.mfaService.startEnrollGip();
      if (totpSecret) {
        this.totpSecret = totpSecret.secretKey;
      }
      this.showAuthenticatorCode = true;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.isContinueForSelectionLoading = false;
    }
  }

  async handleSignInWithEnrolledMethod(verificationCode: string) {
    try {
      if (this.mfaService.resolver?.hints[0]?.factorId === MFA_PHONE_FACTOR_ID) {
        await this.mfaService.signInWithSms(verificationCode, { next: nextWithDefault(this.next) });
        return;
      }
      await this.mfaService.signInWithTOTP(verificationCode, { next: nextWithDefault(this.next) });
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === FIREBASE_ERROR_CODES.INVALID_VERIFICATION_CODE
      ) {
        this.isVerificationCodeValid = false;
        return;
      }
      this.handleError(error);
    }
  }

  handleError(error: unknown) {
    if (error instanceof FirebaseError || error instanceof NoActiveUserError) {
      if (
        error.code === FIREBASE_ERROR_CODES.REQUIRES_RECENT_LOGIN ||
        error.code === FIREBASE_ERROR_CODES.NO_ACTIVE_USER
      ) {
        window.location.href = `/login?next=${this.nextWithDefault}&isLoginExpired=true`;
      }
    }
    this.showErrorMessage();
    this.errorTracking.captureException(error);
  }

  get showVerificationStep() {
    return this.model.mfaInfo.gip_display_name !== null || this.showEnrollmentVerificationStep;
  }

  get title() {
    if (this.mfaService.isLoginVerificationActive) {
      return this.intl.t('mfa.multi_factor_authentication');
    }

    return this.intl.t('mfa.title');
  }

  get formattedPhoneNumber() {
    return formatPhoneNumber(this.selectedCountryCode.phoneCode, this.phoneNumber);
  }

  get mfaType() {
    return this.mfaService.resolver ? this.model.mfaInfo.gip_display_name : this.radioMfaType;
  }

  // Value that is used to request the verification code
  get verificationValue() {
    const { resolver } = this.mfaService;
    const isPhoneFactor = resolver?.hints[0]?.factorId === MFA_PHONE_FACTOR_ID;

    if (this.mfaType === MFA_TYPES.EMAIL) {
      return this.model.email || '';
    }

    if (this.mfaService.isLoginVerificationActive && isPhoneFactor) {
      return (resolver.hints[0] as PhoneMultiFactorInfo).phoneNumber;
    }

    return this.formattedPhoneNumber;
  }

  get nextWithDefault() {
    if (this.next && this.next.startsWith('/')) {
      return this.next;
    }

    return '/';
  }

  get showCaptchaIntermediateScreen() {
    return (
      this.model.mfaInfo.gip_display_name === MFA_TYPES.SMS &&
      this.mfaService.isLoginVerificationActive &&
      !this.loginCaptchaResolved
    );
  }

  get isUserMfaEnforced() {
    return this.model.mfaInfo.enforce_mfa === true;
  }

  get isContinueForVerificationDisabled() {
    const hasEmptyVerificationCode =
      !this.showCaptchaIntermediateScreen && this.verificationCode === '';
    const hasInvalidCaptcha = this.showCaptchaIntermediateScreen && this.captchaCode === '';
    return hasEmptyVerificationCode || hasInvalidCaptcha;
  }

  get isContinueForSelectionDisabled() {
    if (this.mfaType === MFA_TYPES.SMS) {
      return this.phoneNumber === '' || this.captchaCode === '' || !this.hasValidPhoneNumber;
    }

    return false;
  }

  @action
  onChangeMfaType(event: Event) {
    const target = event.target as HTMLInputElement;
    this.radioMfaType = target.value as MFAType;
  }

  @action
  onCountryCodeChange(countryCode: { code: string; phoneCode: string }) {
    this.selectedCountryCode = countryCode;
  }

  @action
  onPhoneNumberChange(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  @action
  async updateIsPhoneNumberValid(isValid: boolean) {
    this.hasValidPhoneNumber = isValid;
  }

  @action
  async createCaptcha(element: HTMLDivElement | null = null) {
    if (element) {
      this.captchaElement = element;
    }
    if (!this.captchaElement || !this.enableCaptcha) {
      return;
    }
    this.captcha = new RecaptchaVerifier(this.firebaseService.auth, this.captchaElement, {});
    if (this.captcha) {
      await this.captcha.render();
      this.captchaCode = await this.captcha.verify();
      if (this.isCaptchaInVerificationCodeEnabled) {
        await this.mfaService.requestSms(
          `+${this.selectedCountryCode.phoneCode}${this.phoneNumber}`,
          this.captcha,
        );
        this.isCaptchaInVerificationCodeEnabled = false;
        // Disabling linting on this line, since this is a false negative.
        // eslint-disable-next-line ember/no-array-prototype-extensions
        this.captcha.clear();
      }
    }
  }

  @action
  onBackButtonClicked() {
    if (this.showEnrollmentVerificationStep && this.showAuthenticatorCode) {
      this.showEnrollmentVerificationStep = false;
      return;
    }
    this.reset();
  }

  @action
  setVerificationCode(code: string) {
    this.isVerificationCodeValid = false;
    this.verificationCode = code;
    // All verification codes are 6 digits.
    // We also allow an empty string because it will give errors otherwise when focus is lost(so automatically when a user goes to their mails)
    if (
      this.verificationCode.length === MFA_VERIFICATION_CODE_LENGTH ||
      this.verificationCode.length === 0
    ) {
      this.isVerificationCodeValid = true;
    }
  }

  @action
  async onResendVerificationCode() {
    if (this.mfaType === MFA_TYPES.SMS) {
      this.isCaptchaInVerificationCodeEnabled = true;
    }
  }

  @action
  async onContinueForVerificationClicked() {
    if (this.isContinueForVerificationLoading) {
      return;
    }

    this.showError = false;

    // If we are in the captcha intermediate screen, we need to resolve the captcha first
    if (this.showCaptchaIntermediateScreen) {
      if (this.captchaCode === '' || !this.captcha) {
        return;
      }

      this.isContinueForVerificationLoading = true;
      try {
        await this.mfaService.requestSms(null, this.captcha);
        this.loginCaptchaResolved = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.isContinueForVerificationLoading = false;
      }
      return;
    }

    if (!this.isVerificationCodeValid || this.verificationCode === '') {
      return;
    }

    this.isContinueForVerificationLoading = true;

    if (this.mfaService.resolver) {
      await this.handleSignInWithEnrolledMethod(this.verificationCode);
      this.isContinueForVerificationLoading = false;
      return;
    }

    try {
      // If we don't have a resolver, we need to enroll
      switch (this.radioMfaType) {
        // TODO-MFA-EMAIL: currently we are not supporting email as a MFA method
        case MFA_TYPES.EMAIL: {
          //   await this.mfaService.verifyEmailForEnrollment(this.verificationCode);
          break;
        }
        case MFA_TYPES.SMS: {
          await this.mfaService.verifySmsForEnrollment(this.verificationCode);
          break;
        }
        case MFA_TYPES.MOBILE: {
          await this.mfaService.verifyAuthenticatorForEnrollment(this.verificationCode);
          break;
        }
        default:
          this.radioMfaType satisfies never;
      }
      this.mfaService.redirectToLogin({ next: nextWithDefault(this.next) });
    } catch (error) {
      this.isVerificationCodeValid = false;
      this.errorTracking.captureException(error);
    } finally {
      this.isContinueForVerificationLoading = false;
    }
  }

  @action
  async onContinueForSelectionClicked() {
    if (this.isContinueForSelectionLoading) {
      return;
    }

    this.showError = false;
    switch (this.radioMfaType) {
      case MFA_TYPES.EMAIL: {
        await this.enrollEmail();
        break;
      }
      case MFA_TYPES.SMS: {
        try {
          if (this.hasValidPhoneNumber && this.captchaCode !== '' && this.captcha) {
            this.isContinueForSelectionLoading = true;
            await this.mfaService.requestSms(
              `+${this.selectedCountryCode.phoneCode}${this.phoneNumber}`,
              this.captcha,
            );
            this.isContinueForSelectionLoading = false;
            this.showEnrollmentVerificationStep = true;
          }
        } catch (error) {
          this.handleError(error);
        } finally {
          this.isContinueForSelectionLoading = false;
        }

        break;
      }
      case MFA_TYPES.MOBILE: {
        if (!this.showAuthenticatorCode) {
          await this.enrollMobile();
          return;
        }

        this.showEnrollmentVerificationStep = true;
        break;
      }
      default:
        this.radioMfaType satisfies never;
    }
  }

  @action
  submitVerificationCodeWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onContinueForVerificationClicked();
    }
  }

  @action
  onSetupLater() {
    this.mfaService.redirectToLogin({ next: nextWithDefault(this.next) });
  }

  @action
  showErrorMessage(
    title: string | null = this.intl.t('general.error_title'),
    message: string | null = this.intl.t('login.error.general'),
  ) {
    this.errorMessage = '';
    this.errorTitle = '';
    if (title) {
      this.errorTitle = title;
    }
    if (message) {
      this.errorMessage = message;
    }
    this.showError = true;
  }
}
