import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

import type ChangeEmailRoute from 'frontend-login/routes/change-email';
import type { ModelFrom } from 'frontend-login/utils/model-from';
import type ErrorTrackingService from 'frontend-utils/services/error-tracking';
import type FetchService from 'password-form/services/fetch';

interface ChangeEmailResponse {
  response: Response;
}
export default class LoginController extends Controller {
  queryParams = ['newEmail', 'next'];

  @service declare intl: IntlService;

  @service('fetch') declare fetch: FetchService;

  @service declare router: RouterService;

  @service declare errorTracking: ErrorTrackingService;

  @tracked newEmail = '';

  @tracked showSuccessScreen = false;

  declare model: ModelFrom<ChangeEmailRoute>;

  @action
  redirectToLogin(showError = false) {
    this.router.transitionTo('login', { queryParams: { showGenericError: showError } });
  }

  @action
  async confirmEmailChange() {
    const { changeEmailCode } = this.model;

    if (!changeEmailCode) {
      this.redirectToLogin(true);
      return;
    }

    try {
      const result = await this.fetch.post<ChangeEmailResponse>(
        `/sso/change-email/${changeEmailCode}/`,
        {},
        {},
      );
      if (result.response.ok) {
        this.showSuccessScreen = true;
      } else {
        this.errorTracking.captureException(result);
        this.redirectToLogin(true);
      }
    } catch (error) {
      this.errorTracking.captureException(error);
      this.redirectToLogin(true);
    }
  }
}
