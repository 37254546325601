import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AlertingService from '@mylighthouse/prism-ember/services/alerting';

import type SharedAuthService from 'frontend-login/services/shared-auth';
import type ErrorTrackingService from 'frontend-utils/services/error-tracking';
import type FetchService from 'password-form/services/fetch';
import type { MfaInfo } from 'password-form/services/mfa-service';
import type MfaService from 'password-form/services/mfa-service';

export default class MfaRoute extends Route {
  @service declare fetch: FetchService;

  @service declare mfaService: MfaService;

  @service declare alerting: AlertingService;

  @service declare errorTracking: ErrorTrackingService;

  @service declare router: RouterService;

  @service('shared-auth') declare sharedAuth: SharedAuthService;

  async model() {
    const email = this.sharedAuth.authenticatedEmail;
    if (!email) {
      this.router.transitionTo('login');
      throw new Error('Email is not available');
    }

    const mfaInfo = await this.requestMfaInfo(email);

    const model = {
      mfaInfo,
      email,
    };

    return model;
  }

  requestMfaInfo(email: string): Promise<MfaInfo> {
    try {
      return this.fetch.fetch('/sso/mfa-info/', {
        email,
      });
    } catch (error) {
      this.alerting.showErrorAlert();
      this.errorTracking.captureException(error);
      throw new Error('MFA info is not available');
    }
  }
}
