import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { modifier } from 'ember-modifier';
import type { MultiFactorError } from 'firebase/auth';

import type AuthenticationService from 'frontend-login/services/authentication';
import type SharedAuthService from 'frontend-login/services/shared-auth';
import { GIP_NAMES, GIP_NAMES_TRANSLATIONS } from 'frontend-login/utils/constant-values';
import type ErrorTrackingService from 'frontend-utils/services/error-tracking';
import type FetchService from 'password-form/services/fetch';
import type MfaService from 'password-form/services/mfa-service';
import { isMultiFactorError, type LoginResponse } from 'password-form/services/mfa-service';
import nextWithDefault from 'password-form/utils/auth-utils';
import { FIREBASE_ERROR_CODES } from 'password-form/utils/constant-values';

export default class LoginController extends Controller {
  queryParams = [
    'email',
    'next',
    'spiderMigrationMessage',
    'idp',
    'showGenericError',
    'continueLogin',
    'isLoginExpired',
  ];

  @service declare fetch: FetchService;

  @service declare authentication: AuthenticationService;

  @service('shared-auth') declare sharedAuth: SharedAuthService;

  @service declare errorTracking: ErrorTrackingService;

  @service declare intl: IntlService;

  @service declare mfaService: MfaService;

  @service declare router: RouterService;

  @tracked email = '';

  @tracked spiderMigrationMessage = undefined;

  @tracked idp = null;

  @tracked showGenericError = false;

  @tracked verificationNeeded = false;

  @tracked next = '';

  @tracked isRequestRunning = false;

  @tracked isLoginExpired = false;

  @tracked emailInputHasErrors = false;

  @tracked continueLogin = false;

  initializeErrorHandling = modifier(() => {
    if (this.showGenericError) {
      this.sharedAuth.showErrorMessage();
    }
  });

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);

    if (localStorage.getItem('isLoginExpired')) {
      this.isLoginExpired = true;
      localStorage.removeItem('isLoginExpired');
    }
  }

  async signInWithPassword() {
    try {
      this.isRequestRunning = true;

      if (!this.sharedAuth.passwordLoginOption) {
        throw new Error('No password login option available');
      }

      let params = {};
      if (this.next) {
        params = {
          next: nextWithDefault(this.next),
        };
      }

      // If the user is not migrated to the firebase authentication system, we need to migrate them first through UM
      if (!this.sharedAuth.passwordLoginOption.is_migrated) {
        this.sharedAuth.cachedMigrateResult = await this.handleMigration(params);

        /**
         * The fetch service doesn't throw an error when the response is not ok, so we need to check it manually
         * If the migration request fails, show an error message and handle the error
         */
        if (
          this.sharedAuth.cachedMigrateResult &&
          !this.sharedAuth.cachedMigrateResult.response.ok
        ) {
          this.sharedAuth.showErrorMessage();
          this.sharedAuth.handleErrors(this.sharedAuth.cachedMigrateResult, true);
          this.isRequestRunning = false;
          return;
        }
      } else {
        // If the user is already connected to firebase, we can sign in directly
        this.sharedAuth.cachedCredential = await this.authentication.signInWithPassword(
          this.sharedAuth.emailInputValue,
          this.sharedAuth.password,
        );
      }

      this.sharedAuth.authenticatedEmail = this.sharedAuth.emailInputValue;
      await this.sharedAuth.completeLoginProcess(params);
    } catch (error: unknown) {
      if (isMultiFactorError(error)) {
        this.handleFirebaseError(error as MultiFactorError);
        return;
      }
      this.sharedAuth.showErrorMessage();
      this.errorTracking.captureException(error);
    } finally {
      this.isRequestRunning = false;
    }
  }

  async handleMigration(params: { next?: string }): Promise<LoginResponse | null> {
    return this.fetch.post('/sso/login-and-migrate/', params, {
      username: this.sharedAuth.emailInputValue,
      password: this.sharedAuth.password,
    });
  }

  handleFirebaseError(error: MultiFactorError): void {
    if (error.code === FIREBASE_ERROR_CODES.MULTI_FACTOR_REQUIRED) {
      /**
       * We set the authenticated email here because even when the user is not completely authenticated,
       * we still need to know the email to continue the MFA process
       */
      this.sharedAuth.authenticatedEmail = this.sharedAuth.emailInputValue;
      this.sharedAuth.solveMfa(error);
      return;
    }

    if (error.code === FIREBASE_ERROR_CODES.INVALID_CREDENTIALS) {
      this.sharedAuth.showErrorMessage(null, this.intl.t('login.error.invalid_credentials'));
      return;
    }

    this.sharedAuth.showErrorMessage();
    this.errorTracking.captureException(error);
  }

  get pageTitle() {
    return this.intl.t('login.sign_in');
  }

  get title() {
    if (this.verificationNeeded) {
      return this.intl.t('login.verify_account_title');
    }
    return this.intl.t('login.title');
  }

  get showPasswordLogin() {
    return this.sharedAuth.passwordLoginOption && !this.sharedAuth.showSSOConfirmationScreen;
  }

  get isFormValid() {
    return this.sharedAuth.isEmailValid;
  }

  get showSubmitButton() {
    return !this.sharedAuth.loginOptions || this.sharedAuth.passwordLoginOption !== undefined;
  }

  get isSubmitButtonDisabled() {
    return (
      this.isRequestRunning ||
      !this.sharedAuth.emailInputValue ||
      (this.shouldRenderPasswordInput && !this.sharedAuth.password)
    );
  }

  get shouldRenderPasswordInput() {
    return !!(this.sharedAuth.passwordLoginOption && !this.sharedAuth.showSSOConfirmationScreen);
  }

  get newPlatformConfirmationText() {
    if (!this.sharedAuth.oldGipName || !this.sharedAuth.activeGipName) {
      return '';
    }
    return this.intl.t('login.change_platform_message', {
      oldPlatform:
        this.sharedAuth.oldGipName === GIP_NAMES.PASSWORD
          ? this.intl.t(GIP_NAMES_TRANSLATIONS[this.sharedAuth.oldGipName])
          : GIP_NAMES_TRANSLATIONS[this.sharedAuth.oldGipName],
      newPlatform:
        this.sharedAuth.activeGipName === GIP_NAMES.PASSWORD
          ? this.intl.t(GIP_NAMES_TRANSLATIONS[this.sharedAuth.activeGipName])
          : GIP_NAMES_TRANSLATIONS[this.sharedAuth.activeGipName],
    });
  }

  get showLoadingScreen() {
    return this.sharedAuth.isRedirecting && !this.sharedAuth.loadingButtonType;
  }

  @action
  submitForm() {
    if (this.isFormValid) {
      this.sharedAuth.showError = false;
      this.sharedAuth.emailInputHasErrors = false;

      if (this.sharedAuth.isEmailValid && !this.sharedAuth.userHasloginOptions) {
        this.sharedAuth.fetchLoginOptions(this.sharedAuth.emailInputValue);
      }

      if (this.sharedAuth.userHasloginOptions && this.sharedAuth.password !== '') {
        this.signInWithPassword();
      }
    }

    if (!this.sharedAuth.isEmailValid) {
      this.sharedAuth.emailInputHasErrors = true;
    }
  }

  @action
  submitFormWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.isSubmitButtonDisabled) {
      this.submitForm();
    }
  }

  @action
  reset() {
    this.sharedAuth.reset();
  }

  /**
   * Connect with new platform
   *
   * These functions are used when the user wants to change their login method, either by their own will or by their administrator forcing them to use SSO.
   */

  @action
  denySSOConfirmation() {
    this.sharedAuth.showSSOConfirmationScreen = false;
    this.sharedAuth.showError = true;
    this.sharedAuth.errorMessage = this.intl.t('login.error.deny_sso');
  }

  @action
  confirmNewPlatform() {
    this.sharedAuth.confirmNewPlatform();
  }

  @action
  cancelConnectWithNewPlatform() {
    this.sharedAuth.connectWithNewPlatformConfirmation = false;
  }

  @action
  applyConnectWithNewPlatform() {
    this.sharedAuth.connectWithNewPlatformConfirmation = false;
    this.sharedAuth.connectWithNewPlatformInProcess = true;
    this.verificationNeeded = true;
    this.submitForm();
  }
}
