import 'core-js/stable';
/**
 * Required for compiling generators/async functions to ES5. Can be removed once we drop old browser support.
 * https://babeljs.io/docs/babel-polyfill
 */
import 'regenerator-runtime/runtime';
import 'decorator-transforms/globals';
import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';

import config from 'frontend-login/config/environment';
import { initializeErrorTracking } from 'frontend-utils/services/error-tracking';

import 'frontend-login/scss/app.scss';
import '@mylighthouse/prism-foundation/styles/tailwind.css';

initializeErrorTracking(config['@sentry/ember'].sentry);

export default class App extends Application {
  modulePrefix = config.modulePrefix;

  podModulePrefix = config.podModulePrefix;

  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
