import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { BannerStyle } from '@mylighthouse/prism-ember/components/prism/banner';
import type IntlService from 'ember-intl/services/intl';
import type { MultiFactorError, UserCredential } from 'firebase/auth';

import type AuthenticationService from 'frontend-login/services/authentication';
import {
  AUTHENTICATION,
  EMAIL_REGEX,
  GIP_NAMES,
  GIP_NAMES_WITHOUT_PASSWORD,
  PROVIDER_TYPES,
  RESPONSE_CODE,
  SSO_TYPES,
  type GIPName,
  type GIPNameWithoutPassword,
  type ProviderType,
  type SSOType,
} from 'frontend-login/utils/constant-values';
import type ErrorTrackingService from 'frontend-utils/services/error-tracking';
import type FetchService from 'password-form/services/fetch';
import type MfaService from 'password-form/services/mfa-service';
import { isMultiFactorError, type LoginResponse } from 'password-form/services/mfa-service';
import nextWithDefault from 'password-form/utils/auth-utils';
import { FIREBASE_ERROR_CODES } from 'password-form/utils/constant-values';

/* eslint-disable @typescript-eslint/naming-convention */
interface LoginOption {
  identity_provider: {
    name: string;
    provider_type: string;
    gip_name: GIPName;
    location?: string;
  };
  type: string;
  location: string;
  is_migrated: boolean;
}
/* eslint-enable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/naming-convention */
interface LoginOptionResponse {
  login_options: LoginOption[];
}
/* eslint-enable @typescript-eslint/naming-convention */

interface StorageData {
  connectWithNewPlatformInProcess: boolean;
  gipName: GIPName;
}

type ParamsType = {
  next?: string;
};

const ResponseCode = {
  OK: 'ok',
  MfaSent: 'mfa_sent',
  ProviderConfirmationRequired: 'provider_confirmation_required',
  Error: 'error',
} as const;

type ResponseCode = keyof typeof ResponseCode;

interface ResponseMeta {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  login_option?: LoginOption;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  error_message?: string;
}

export default class SharedAuthService extends Service {
  @service declare authentication: AuthenticationService;

  @service declare intl: IntlService;

  @service declare errorTracking: ErrorTrackingService;

  @service declare fetch: FetchService;

  @service declare router: RouterService;

  @service declare mfaService: MfaService;

  @tracked loginOptions: LoginOption[] | null = null;

  @tracked emailInputValue = '';

  @tracked emailInputHasErrors = false;

  @tracked password = '';

  @tracked bannerStyle: BannerStyle = 'critical';

  @tracked showError = false;

  @tracked errorTitle = '';

  @tracked errorMessage = '';

  @tracked loadingButtonType: null | GIPName = null;

  @tracked isRedirecting = false;

  @tracked connectWithNewPlatformConfirmation = false;

  @tracked showSSOConfirmationScreen = false;

  /**
   * This variable is used before redirecting to distinguish which redirecting message should be shown
   */
  @tracked isBeingRedirected = false;

  // Support legacy 2FA screen
  @tracked show2FAScreen = false;

  connectWithNewPlatformInProcess = false;

  token: string | undefined;

  activeLoginOption: LoginOption | undefined;

  activeGipName: GIPName | undefined;

  oldGipName: GIPName | undefined;

  /**
   * Cached response from login-and-migrate endpoint
   */
  cachedMigrateResult: LoginResponse | null = null;

  /**
   * Cached response from firebase after login
   */
  cachedCredential: UserCredential | null = null;

  authenticatedEmail: string | undefined | null = null;

  needsMfa = false;

  signInWithSocialActions = {
    [GIP_NAMES.GOOGLE]: this.signInWithGoogle,
    [GIP_NAMES.MICROSOFT]: this.signInWithMicrosoft,
    [GIP_NAMES.SAML]: this.signInWithSaml,
  };

  constructor() {
    // eslint-disable-next-line prefer-rest-params
    super(...arguments);
    this.router.on('routeDidChange', () => {
      const { queryParams } = this.router.currentRoute;
      if (queryParams['isRedirecting'] !== undefined) {
        this.isRedirecting = queryParams['isRedirecting'] === 'true';
      }
    });
  }

  isSignInMethodWithoutPassword(idp: GIPName | ProviderType): idp is GIPNameWithoutPassword {
    return Object.values(GIP_NAMES_WITHOUT_PASSWORD).includes(idp as GIPNameWithoutPassword);
  }

  setIsRedirecting(value: boolean) {
    this.isRedirecting = value;
    if (this.isRedirecting) {
      this.router.transitionTo({ queryParams: { isRedirecting: value } });
    } else {
      this.router.transitionTo({ queryParams: { isRedirecting: undefined } });
    }
  }

  /**
   * Redirect to the selected identity provider
   * The user is redirected to their IDP if it's set in the URL or if it is their only login option
   */
  redirectToIdp(idp: GIPName | ProviderType) {
    this.setIsRedirecting(true);
    this.isBeingRedirected = true;

    if (this.isSignInMethodWithoutPassword(idp)) {
      this.signInWithSocialActions[idp]();
    } else if (idp.startsWith('saml.')) {
      this.signInWithSaml(GIP_NAMES.SAML, idp);
    } else {
      this.setIsRedirecting(false);
      this.showErrorMessage();
    }
  }

  /**
   * Handle the logic after the user has been redirected back to the application after logging in with their IDP
   */
  async handleRedirectLogic(result: UserCredential | null, error: unknown | null = null) {
    if (!error && !result) {
      if (!this.isBeingRedirected) {
        this.setIsRedirecting(false);
      }
      return;
    }

    if (error) {
      if (isMultiFactorError(error) && error.code === FIREBASE_ERROR_CODES.MULTI_FACTOR_REQUIRED) {
        // eslint-disable-next-line no-underscore-dangle
        this.authenticatedEmail = error.customData._serverResponse.email;
        await this.fetchLoginOptions(this.authenticatedEmail!, false);

        // First we need to check if the user needs to confirm a new platform
        const storageData = localStorage.getItem(AUTHENTICATION);
        let shouldShowConfirmation = false;

        if (storageData) {
          localStorage.removeItem(AUTHENTICATION);
          const data = JSON.parse(storageData);
          const hasGipNameInOptions = this.loginOptions?.some(
            (option) => option.identity_provider.gip_name === data.gipName,
          );
          if (data.gipName && !hasGipNameInOptions) {
            shouldShowConfirmation = true;
            this.activeGipName = data.gipName;
            if (this.loginOptions?.length) {
              this.oldGipName = this.loginOptions[0]?.identity_provider.gip_name;
            }

            this.connectWithNewPlatformInProcess = true;
          }
        }

        if (shouldShowConfirmation) {
          this.connectWithNewPlatformConfirmation = true;
          this.setIsRedirecting(false);

          return;
        }

        // If we don't need to show the confirmation screen, proceed with MFA
        await this.solveMfa(error);
        this.setIsRedirecting(false);

        return;
      }
      this.setIsRedirecting(false);
      this.showErrorMessage();
      this.errorTracking.captureException(error);
      return;
    }

    if (!result || !result.user.email) {
      this.setIsRedirecting(false);
      this.showErrorMessage();
      return;
    }

    this.setIsRedirecting(true);
    const { queryParams } = this.router.currentRoute;
    this.cachedCredential = result;

    await this.completeLoginProcess(
      {
        next: nextWithDefault(queryParams['next']),
      },
      true,
    );
  }

  /**
   * This function is used when the user has MFA enabled or enforced.
   *
   * Sets the MFA resolver to validate the status of the user, to check if the user has MFA enabled (already existing method)
   * or if the user is enforced and needs to enroll a MFA method.
   */
  async solveMfa(error: MultiFactorError) {
    const { queryParams } = this.router.currentRoute;

    this.mfaService.setMultiFactorResolver(error);
    const params: ParamsType = {};
    if (queryParams['next']) {
      params.next = nextWithDefault(queryParams['next']);
    }
    await this.router.transitionTo('mfa', { queryParams: params });
  }

  async fetchLoginOptions(email: string, redirect = true) {
    try {
      const response = await this.fetch.fetch<LoginOptionResponse>('/sso/login-options/', {
        email,
      });
      this.loginOptions = response.login_options;

      // If the user has only one login option, redirect to it
      if (redirect && this.loginOptions?.length === 1 && !this.hasOnlyPasswordLoginOption) {
        const identityProvider = this.loginOptions[0]?.identity_provider;
        const providerType =
          identityProvider?.provider_type === PROVIDER_TYPES.SAML
            ? identityProvider.provider_type
            : identityProvider?.gip_name;
        if (providerType) {
          this.redirectToIdp(providerType);
        }
      }
    } catch (error) {
      this.showErrorMessage();
      this.errorTracking.captureException(error);
    }
  }

  async redirectToApp(next?: string) {
    const result = await this.verifyUserToken();
    if (this.connectWithNewPlatformInProcess && result.token && this.activeGipName) {
      await this.authentication.acceptSso(result.token, this.activeGipName);
    }
    window.location.href = nextWithDefault(next);
  }

  async verifyUserToken(params = {}) {
    const userCredential = this.cachedCredential ?? this.mfaService.userCredential;

    if (!userCredential?.user) {
      throw new Error('No user credentials available');
    }

    const idToken = await userCredential.user.getIdToken();
    const result = await this.authentication.verifyTokenOnUM(idToken, params);
    result.token = idToken;

    return result;
  }

  async handleNonOkResponse(
    code: ResponseCode,
    meta?: ResponseMeta,
    afterRedirect = false,
  ): Promise<void> {
    switch (code) {
      case RESPONSE_CODE.MFA_SENT:
        this.show2FAScreen = true;
        break;

      case RESPONSE_CODE.PROVIDER_CONFIRMATION_REQUIRED:
        if (meta?.login_option) {
          this.setIsRedirecting(false);
          this.activeLoginOption = meta.login_option;
          this.activeGipName = meta.login_option.identity_provider.gip_name;

          /**
           * If the user wants to connect with a new platform, we need to show the connect with new platform screen,
           * otherwise show the SSO confirmation screen
           */
          if (afterRedirect) {
            if (this.loginOptions) {
              this.oldGipName = this.loginOptions[0]?.identity_provider.gip_name;
            }
            this.connectWithNewPlatformConfirmation = true;
          } else {
            this.showSSOConfirmationScreen = true;
          }
        }
        break;

      default:
        this.showErrorMessage(meta?.error_message);
        this.errorTracking.captureException(new Error(`Unexpected response code: ${code}`));
    }
  }

  async handleSuccessfulAuth(next?: string) {
    if (!next) {
      return;
    }

    try {
      if (this.needsMfa) {
        this.authenticatedEmail = this.emailInputValue;
        this.authentication.redirectToMfa(nextWithDefault(next));
        return;
      }

      this.redirectToApp(next);
    } catch (error) {
      this.showErrorMessage();
      this.errorTracking.captureException(error);
    }
  }

  /**
   * This function processes the authentication response from the server and handles different response codes accordingly.
   */
  async processAuthenticationResponse(response: LoginResponse, afterRedirect = false) {
    const { code, next, meta } = response.data;

    if (code !== RESPONSE_CODE.OK) {
      return this.handleNonOkResponse(code as ResponseCode, meta, afterRedirect);
    }

    return this.handleSuccessfulAuth(next);
  }

  /**
   * Continue the login/registration flow after the user has logged in with their IDP (after redirect) or password
   */
  async completeLoginProcess(params = {}, afterRedirect = false) {
    let result = this.cachedMigrateResult;
    let userEmail: string | null = null;

    if (!this.cachedMigrateResult) {
      // If the user didn't need to migrate, we need to get their credentials from cachedCredential (from firebase)
      const userCredentialFallback = this.cachedCredential ?? this.mfaService.userCredential;
      if (!userCredentialFallback || !userCredentialFallback.user.email) {
        this.showErrorMessage();
        return;
      }

      userEmail = userCredentialFallback.user.email;
      this.needsMfa = await this.authentication.shouldShowMfa(userEmail);

      const idToken = await userCredentialFallback.user.getIdToken();
      result = await this.authentication.verifyTokenOnUM(idToken, params);
      result.token = idToken;
    } else {
      this.needsMfa = true;
    }

    if (!result) {
      this.showErrorMessage();
      return;
    }

    if (result.token) {
      this.token = result.token;
    }

    // Resolve email from all possible sources
    userEmail = result.email || userEmail || this.emailInputValue;
    this.emailInputValue = userEmail;
    if (!result.response.ok) {
      this.handleErrors(result, true);
      return;
    }

    if (userEmail) {
      await this.fetchLoginOptions(userEmail, false);
    }

    /**
     * Get the data that was stored in localStorage before being redirected to the authentication method
     * The data is used to continue the flow after the user has selected a new platform
     */
    const storageData = localStorage.getItem(AUTHENTICATION);
    if (storageData) {
      const data = JSON.parse(storageData);
      localStorage.removeItem(AUTHENTICATION);
      this.connectWithNewPlatformInProcess = data.connectWithNewPlatformInProcess;
    }

    if (this.connectWithNewPlatformInProcess) {
      this.setIsRedirecting(false);
      this.connectWithNewPlatformInProcess = false;
      this.confirmNewPlatform(result);
      return;
    }

    await this.processAuthenticationResponse(result, afterRedirect);
  }

  /**
   * This function handles the errors received from the server and shows the appropriate error message.
   */
  handleErrors(result: LoginResponse, isPasswordSignIn = false) {
    const { status } = result.response;
    const { code } = result.data;

    if (code === RESPONSE_CODE.INVALID_CREDENTIALS && isPasswordSignIn) {
      this.showErrorMessage(null, this.intl.t('login.error.invalid_credentials'));
      return;
    }

    if (code === RESPONSE_CODE.INVALID_CREDENTIALS && !isPasswordSignIn) {
      this.showErrorMessage(null, this.intl.t('login.error.no_access'));
      return;
    }

    if (code === RESPONSE_CODE.EMAIL_ALREADY_EXISTS) {
      this.showErrorMessage(null, this.intl.t('login.error.email_already_exists'));
      return;
    }

    if (code === RESPONSE_CODE.MFA_ENFORCED) {
      this.authenticatedEmail = this.emailInputValue;
      this.authentication.redirectToMfa(nextWithDefault(result.data.next));
      return;
    }

    if (
      code === RESPONSE_CODE.LOGIN_OPTION_UNAVAILABLE ||
      code === RESPONSE_CODE.ACCOUNT_ISSUE ||
      code === RESPONSE_CODE.LOGIN_OPTION_NOT_FOUND ||
      code === RESPONSE_CODE.GENERIC
    ) {
      this.showErrorMessage();
      this.errorTracking.captureException(result.data);
      return;
    }

    if (status === 500) {
      this.showErrorMessage();
      this.errorTracking.captureException(result.response);
    }
  }

  storeLocalStorage(data: StorageData) {
    localStorage.setItem(AUTHENTICATION, JSON.stringify(data));
  }

  get shouldVerifyNewPlatform() {
    return !!(this.connectWithNewPlatformInProcess && this.activeLoginOption && this.activeGipName);
  }

  get isEmailValid() {
    return EMAIL_REGEX.test(this.emailInputValue);
  }

  get hasOnlyPasswordLoginOption() {
    return this.loginOptions?.length === 1 && this.passwordLoginOption;
  }

  get passwordLoginOption(): LoginOption | undefined {
    if (!this.loginOptions?.length) {
      return undefined;
    }

    return this.loginOptions.find(
      (option) => option.identity_provider.provider_type === PROVIDER_TYPES.EMAIL_AND_PASSWORD,
    );
  }

  get samlLoginOption() {
    return (
      this.loginOptions?.find(
        (option) => option.identity_provider.provider_type === PROVIDER_TYPES.SAML,
      ) ?? this.activeLoginOption
    );
  }

  get userHasloginOptions() {
    return Boolean(this.loginOptions?.length);
  }

  get defaultSsoLoginOptions() {
    return [
      {
        type: SSO_TYPES.GOOGLE,
        gipType: GIP_NAMES.GOOGLE,
        onClickAction: () => this.signInWithGoogle(GIP_NAMES.GOOGLE),
      },
      {
        type: SSO_TYPES.MICROSOFT,
        gipType: GIP_NAMES.MICROSOFT,
        onClickAction: () => this.signInWithMicrosoft(GIP_NAMES.MICROSOFT),
      },
    ];
  }

  /**
   * Filter the login options to only show the social login options
   */
  get ssoLoginOptions() {
    // Add default social login options if there are none
    if (!this.loginOptions?.length && !this.hasOnlyPasswordLoginOption) {
      return this.defaultSsoLoginOptions;
    }

    if (!this.loginOptions) {
      return [];
    }

    const filteredLoginOptions = this.loginOptions.filter(
      (option) => option.identity_provider.provider_type !== PROVIDER_TYPES.EMAIL_AND_PASSWORD,
    );
    return filteredLoginOptions.map((option: LoginOption) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { provider_type, gip_name, name } = option.identity_provider;
      const isSaml = provider_type === PROVIDER_TYPES.SAML;

      /**
       * If the provider is SAML, its gip_name is saml.<saml_provider_name>
       * The ButtonSingleSignOn component expects google, microsoft or saml for its type
       * Therefore, for SAML we use provider name as gip_name
       */
      const gipType: GIPName = isSaml ? GIP_NAMES.SAML : gip_name;
      const type = isSaml ? PROVIDER_TYPES.SAML : (name.toLowerCase() as SSOType);

      return {
        type,
        gipType,
        onClickAction: () =>
          this.signInWithSocialActions[gipType as keyof typeof this.signInWithSocialActions](
            gipType,
          ),
      };
    });
  }

  @action
  async signInWithGoogle(gipName: GIPName | null = null): Promise<void> {
    try {
      if (gipName) {
        this.loadingButtonType = gipName;
      }
      this.setIsRedirecting(true);

      // Store the data in localStorage before redirecting to the IDP page
      const storageData: StorageData = {
        connectWithNewPlatformInProcess: this.connectWithNewPlatformInProcess,
        gipName: GIP_NAMES.GOOGLE,
      };

      localStorage.setItem(AUTHENTICATION, JSON.stringify(storageData));

      await this.authentication.signInWithGoogle();
    } catch (error) {
      this.errorTracking.captureException(error);
      this.showErrorMessage();
    }
  }

  @action
  async signInWithMicrosoft(gipName: GIPName | null = null) {
    try {
      if (gipName) {
        this.loadingButtonType = gipName;
      }
      this.setIsRedirecting(true);

      // Store the data in localStorage before redirecting to the IDP page
      const storageData: StorageData = {
        connectWithNewPlatformInProcess: this.connectWithNewPlatformInProcess,
        gipName: GIP_NAMES.MICROSOFT,
      };

      localStorage.setItem(AUTHENTICATION, JSON.stringify(storageData));

      await this.authentication.signInWithMicrosoft();
    } catch (error) {
      this.errorTracking.captureException(error);
      this.showErrorMessage();
    }
  }

  @action
  async signInWithSaml(providerType: GIPName | null = null, gipName: string | null = null) {
    try {
      if (providerType) {
        this.loadingButtonType = providerType;
      }
      this.setIsRedirecting(true);
      const location = this.samlLoginOption?.identity_provider.location;
      if (location && location.startsWith('/')) {
        window.location.href = location;
        return;
      }

      // Store the data in localStorage before redirecting to the IDP page
      // Use a default GIP name (SAML) if none is provided
      const storageData: StorageData = {
        connectWithNewPlatformInProcess: this.connectWithNewPlatformInProcess,
        gipName: GIP_NAMES.SAML,
      };

      // If we have a specific SAML provider, use that instead
      if (this.samlLoginOption?.identity_provider.gip_name) {
        storageData.gipName = this.samlLoginOption.identity_provider.gip_name;
      }

      localStorage.setItem(AUTHENTICATION, JSON.stringify(storageData));

      if (!this.samlLoginOption && !gipName) {
        this.showErrorMessage();
        return;
      }

      const gip = gipName || this.samlLoginOption?.identity_provider.gip_name;
      if (!gip) {
        throw new Error('GIP name is undefined');
      }
      await this.authentication.signInWithSaml(gip);
    } catch (error) {
      this.errorTracking.captureException(error);
      this.showErrorMessage();
    }
  }

  /**
   * This function is used to continue the flow after the user has selected a new platform to connect with.
   * This can happen when the user wants to change their login method, either by their own will or by their administrator forcing them to use SSO.
   */
  @action
  async confirmNewPlatform(loginResponse: LoginResponse | null = null) {
    this.showSSOConfirmationScreen = false;
    try {
      if (!this.token || !this.activeLoginOption || !this.activeGipName) {
        throw new Error('Token or gip is undefined');
      }

      const result = await this.authentication.acceptSso(this.token, this.activeGipName);

      if (result.ok && loginResponse) {
        this.connectWithNewPlatformInProcess = false;
        await this.processAuthenticationResponse(loginResponse);
        return;
      }

      // Redirect to the new platform
      if (result.ok && this.activeLoginOption) {
        const idp =
          this.activeLoginOption.identity_provider.provider_type === PROVIDER_TYPES.SAML
            ? PROVIDER_TYPES.SAML
            : this.activeLoginOption.identity_provider.gip_name;
        this.redirectToIdp(idp);
        this.connectWithNewPlatformInProcess = false;
        return;
      }
      this.connectWithNewPlatformInProcess = false;

      const data: LoginResponse = await result.json();
      this.handleErrors(data);
    } catch (error) {
      this.showErrorMessage();
      this.errorTracking.captureException(error);
    }
  }

  @action
  setEmail(event: Event) {
    // Reset the login options if the user changes the email
    if (event.isTrusted && this.loginOptions) {
      this.loginOptions = null;
    }
    this.emailInputHasErrors = false;
    this.showError = false;
    this.password = '';
    this.emailInputValue = (event.target as HTMLInputElement).value;
  }

  @action
  setPassword(event: Event) {
    this.password = (event.target as HTMLInputElement).value;
  }

  @action
  showErrorMessage(
    title: string | null | undefined = this.intl.t('general.error_title'),
    message: string | null | undefined = this.intl.t('login.error.general'),
    bannerStyle: BannerStyle | null | undefined = 'critical',
  ) {
    this.errorMessage = '';
    this.errorTitle = '';
    this.bannerStyle = 'critical';
    if (title) {
      this.errorTitle = title;
    }
    if (message) {
      this.errorMessage = message;
    }
    if (bannerStyle) {
      this.bannerStyle = bannerStyle;
    }
    this.showError = true;
    this.setIsRedirecting(false);
  }

  @action
  reset() {
    this.emailInputValue = '';
    this.password = '';
    this.loginOptions = null;
    this.emailInputHasErrors = false;
    this.showError = false;
    this.errorTitle = '';
    this.errorMessage = '';
    this.show2FAScreen = false;
  }
}
