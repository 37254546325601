/* import __COLOCATED_TEMPLATE__ from './loading-screen.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

export interface LoadingScreenSignature {
  Args: {
    isBeingRedirected: boolean;
  };
}

const LoadingScreen = templateOnlyComponent<LoadingScreenSignature>();

export default LoadingScreen;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    LoadingScreen: typeof LoadingScreen;
  }
}
