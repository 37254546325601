import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
  OAuthProvider,
  SAMLAuthProvider,
  getRedirectResult,
} from 'firebase/auth';

import type { GIPName } from 'frontend-login/utils/constant-values';
import { getHostDomain } from 'frontend-utils/utils/domain-checks';
import type FetchService from 'password-form/services/fetch';
import type FirebaseService from 'password-form/services/firebase-service';
import type { LoginResponse, MfaInfo } from 'password-form/services/mfa-service';
import nextWithDefault from 'password-form/utils/auth-utils';

type ParamsType = {
  next?: string;
};

export default class AuthenticationService extends Service {
  @service('fetch') declare fetchService: FetchService;

  @service declare router: RouterService;

  @service declare firebaseService: FirebaseService;

  /**
   * Flag to prevent multiple redirects after the user has been logged in with their idp.
   */
  isRedirectProcessed = false;

  /**
   * Gets the redirect result from the authentication provider.
   */
  async handleRedirect() {
    if (this.isRedirectProcessed) {
      return null;
    }

    this.isRedirectProcessed = true;
    return getRedirectResult(this.firebaseService.auth);
  }

  /**
   * Checks if the user should be redirected to the MFA flow.
   *
   * This is based on if the user has MFA enforced or if they have seen the MFA screen before.
   * If the user has a GIP display name (i.e. a method already enable), they should not see the MFA selection screen.
   */
  async shouldShowMfa(email: string) {
    const mfaInfo: MfaInfo = await this.fetchService.fetch('/sso/mfa-info/', {
      email,
    });
    // Return early if GIP is configured
    if (mfaInfo.gip_display_name) {
      return false;
    }

    // Show MFA if enforced or never shown before
    return mfaInfo.enforce_mfa || !mfaInfo.mfa_was_shown;
  }

  async redirectToMfa(next: string) {
    const queryParams: ParamsType = {};
    if (next) {
      queryParams.next = nextWithDefault(next);
    }

    this.router.transitionTo('mfa', {
      queryParams,
    });
  }

  async signInWithPassword(email: string, password: string) {
    return signInWithEmailAndPassword(this.firebaseService.auth, email, password);
  }

  async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    return signInWithRedirect(this.firebaseService.auth, provider);
  }

  async signInWithMicrosoft() {
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    return signInWithRedirect(this.firebaseService.auth, provider);
  }

  async signInWithSaml(gipName: string) {
    const provider = new SAMLAuthProvider(gipName);
    return signInWithRedirect(this.firebaseService.auth, provider);
  }

  async verifyTokenOnUM(token: string, params: Record<string, unknown> = {}) {
    return this.fetchService.post<LoginResponse>('/sso/login/', params, {
      token,
    });
  }

  /**
   * This function sends a request to BE to accept the selected SSO method as new login method.
   * The method can be changed either by themselves or set by their manager.
   *
   * @context If user is changed by their admin to be an enforced SSO user, they still need to accept or deny the change.
   */
  async acceptSso(token: string, gipName: GIPName | string) {
    return fetch(`${getHostDomain()}/sso/accept-sso/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': (
          document.querySelector('input[name="csrfmiddlewaretoken"]') as HTMLInputElement
        )?.value,
      },
      body: JSON.stringify({
        token,
        gip_name: gipName,
      }),
    });
  }

  get nextWithDefault() {
    const { queryParams } = this.router.currentRoute;
    if (queryParams['next'] && queryParams['next'].startsWith('/')) {
      return queryParams['next'];
    }

    return '/';
  }
}
