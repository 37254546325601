import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type { UserCredential } from 'firebase/auth';

import type LoginController from 'frontend-login/controllers/login';
import type AuthenticationService from 'frontend-login/services/authentication';
import type SharedAuthService from 'frontend-login/services/shared-auth';
import type ErrorTrackingService from 'frontend-utils/services/error-tracking';

interface QueryParams {
  next?: string;
  idp?: string;
  isRedirecting?: boolean;
}

export default class LoginRoute extends Route {
  queryParams = {
    next: {
      refreshModel: false,
    },
    idp: {
      refreshModel: false,
    },
    isRedirecting: {
      refreshModel: false,
    },
  };

  @service declare router: RouterService;

  @service declare intl: IntlService;

  @service declare errorTracking: ErrorTrackingService;

  @service declare authentication: AuthenticationService;

  @service('shared-auth') declare sharedAuth: SharedAuthService;

  async beforeModel() {
    this.authentication.handleRedirect().then(
      (result: UserCredential | null) => this.sharedAuth.handleRedirectLogic(result, null),
      (error: unknown) => this.sharedAuth.handleRedirectLogic(null, error),
    );
  }

  setupController(controller: LoginController, model: unknown, transition: Transition) {
    super.setupController(controller, model, transition);
    const queryParams: QueryParams = this.paramsFor(this.routeName);

    if (controller.email) {
      controller.sharedAuth.emailInputValue = controller.email;
    }

    // IDP (Identity Provider) is set in the URL when the user should be redirected to a specific IDP without showing the login page

    if (controller.idp) {
      controller.sharedAuth.redirectToIdp(controller.idp);
      controller.idp = null;
      this.router.replaceWith('login', { queryParams: { idp: null } });
    }

    // If the user stays for too long in the MFA screen, the login session will expire, and the user will be redirected to the login page
    if (controller.isLoginExpired) {
      controller.sharedAuth.showErrorMessage(
        this.intl.t('login.error.login_expired_title'),
        this.intl.t('login.error.login_expired_description'),
        'warning',
      );
    }

    if (controller.continueLogin) {
      controller.sharedAuth.redirectToApp(controller.next);
    }

    if (queryParams.isRedirecting) {
      controller.sharedAuth.isRedirecting = true;
    }
  }
}
