import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type { UserCredential } from 'firebase/auth';

import type RegisterController from 'frontend-login/controllers/register';
import type AuthenticationService from 'frontend-login/services/authentication';
import type SharedAuthService from 'frontend-login/services/shared-auth';

interface QueryParams {
  next?: string;
  isRedirecting?: boolean;
}

export default class RegisterRoute extends Route {
  queryParams = {
    next: {
      refreshModel: false,
    },
    isRedirecting: {
      refreshModel: false,
    },
    email: {
      refreshModel: false,
    },
    emailVerificationCode: {
      refreshModel: false,
    },
    firstName: {
      refreshModel: false,
    },
    isHandlingRedirect: {
      refreshModel: false,
    },
  };

  @service declare router: RouterService;

  @service declare authentication: AuthenticationService;

  @service('shared-auth') declare sharedAuth: SharedAuthService;

  async beforeModel(transition: Transition) {
    /**
     * In the registration page it is not possible to edit the email, so if the email is not present in the query params,
     * the user should be redirected to the login page
     */
    if (!transition.to.queryParams['email']) {
      this.router.transitionTo('/login');
      return;
    }

    this.authentication.handleRedirect().then(
      (result: UserCredential | null) => this.sharedAuth.handleRedirectLogic(result, null),
      (error: unknown) => this.sharedAuth.handleRedirectLogic(null, error),
    );
  }

  setupController(controller: RegisterController, model: unknown, transition: Transition) {
    super.setupController(controller, model, transition);
    const queryParams: QueryParams = this.paramsFor(this.routeName);

    if (controller.email && !controller.isHandlingRedirect) {
      controller.isHandlingRedirect = true;
      controller.sharedAuth.emailInputValue = controller.email;
      controller.sharedAuth.fetchLoginOptions(controller.email);
    } else {
      controller.isHandlingRedirect = false;
    }

    if (queryParams.isRedirecting) {
      controller.sharedAuth.isRedirecting = true;
    }
  }
}
