import EmberRouter from '@ember/routing/router';

import config from 'frontend-login/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;

  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('firebase', { path: '/' }, function () {
    this.route('password-reset', { resetNamespace: true });
    this.route('login', { resetNamespace: true });
    this.route('register', { resetNamespace: true });
    this.route('mfa', { resetNamespace: true });
    this.route('change-email', { path: '/change-email/:code/', resetNamespace: true });
  });

  return true;
});
