import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type ErrorTrackingService from 'frontend-utils/services/error-tracking';
import type FirebaseService from 'password-form/services/firebase-service';

export default class FirebaseRoute extends Route {
  @service declare firebaseService: FirebaseService;

  @service declare errorTracking: ErrorTrackingService;

  async beforeModel() {
    try {
      await this.firebaseService.initialize();
    } catch (error) {
      this.errorTracking.captureException(error);
    }
  }
}
