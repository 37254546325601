import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

import type AuthenticationService from 'frontend-login/services/authentication';
import type SharedAuthService from 'frontend-login/services/shared-auth';
import { RESPONSE_CODE } from 'frontend-login/utils/constant-values';
import type ErrorTrackingService from 'frontend-utils/services/error-tracking';
import type FetchService from 'password-form/services/fetch';
import type { LoginResponse } from 'password-form/services/mfa-service';
import nextWithDefault from 'password-form/utils/auth-utils';

export default class RegisterController extends Controller {
  // email and emailVerificationCode are the only QP that are always required for a successful registration
  queryParams = [
    'firstName',
    'isSsoEnforced',
    'email',
    'next',
    'emailVerificationCode',
    'isHandlingRedirect',
  ];

  @service declare fetch: FetchService;

  @service('shared-auth') declare sharedAuth: SharedAuthService;

  @service declare errorTracking: ErrorTrackingService;

  @service declare authentication: AuthenticationService;

  @service declare intl: IntlService;

  @service declare router: RouterService;

  @tracked email = '';

  @tracked firstName = '';

  @tracked isSsoEnforced = false;

  @tracked repeatPassword = '';

  @tracked isNewPasswordValid = false;

  @tracked isRequestRunning = false;

  @tracked next = '';

  @tracked emailVerificationCode = '';

  @tracked isHandlingRedirect = false;

  /**
   * We don't want to show any error if the user needs to be redirected to the MFA page.
   *
   *  @context When the user is MFA enforced we receive from BE the error code 'sso.error.mfa_enforced'.
   * In a normal flow we should show an error if that code is different from 'ok',
   * but because what we want is to redirect the user to the MFA flow we skip this if and allow the user to continue its registration.
   */
  async signUpWithPassword() {
    this.isRequestRunning = true;
    let result: LoginResponse;
    let params = {};
    if (this.next) {
      params = {
        next: nextWithDefault(this.next),
      };
    }

    try {
      result = await this.fetch.post('/sso/register/', params, {
        email: this.sharedAuth.emailInputValue,
        password: this.sharedAuth.password,
        email_verification_code: this.emailVerificationCode,
      });

      if (!result.response.ok && result.data.code !== RESPONSE_CODE.MFA_ENFORCED) {
        this.isRequestRunning = false;
        this.sharedAuth.handleErrors(result, true);
        return;
      }

      this.sharedAuth.cachedCredential = await this.authentication.signInWithPassword(
        this.sharedAuth.emailInputValue,
        this.sharedAuth.password,
      );

      this.isRequestRunning = false;

      // After the user is authenticated, we need to set the authenticated email to continue the MFA process
      this.sharedAuth.authenticatedEmail = this.sharedAuth.cachedCredential.user.email;
      /**
       * Every time a user is registered, they will be redirected to the MFA page
       * If MFA is not enforced, they will be able to skip
       * If MFA is enforced, they will need to continue the MFA process
       */
      await this.authentication.redirectToMfa(nextWithDefault(this.next));
    } catch (error) {
      this.isRequestRunning = false;
      this.sharedAuth.showErrorMessage();
      this.errorTracking.captureException(error);
    }
  }

  get title() {
    return this.intl.t('login.welcome_user', {
      firstName: this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1),
    });
  }

  get pageTitle() {
    return this.intl.t('login.sign_up');
  }

  get isRepeatPasswordValid() {
    return this.sharedAuth.password === this.repeatPassword;
  }

  get arePasswordsValid() {
    return this.isNewPasswordValid && this.isRepeatPasswordValid;
  }

  get submitButtonLabel() {
    // If SSO is enforced, the user will have only one login option. Therefore, we only show the next button and redirect the user to their SSO page
    if (this.isSsoEnforced) {
      return this.intl.t('login.next');
    }

    return this.intl.t('login.sign_up');
  }

  get isFormValid() {
    if (!this.isSsoEnforced) {
      return this.isNewPasswordValid && this.sharedAuth.isEmailValid && this.isRepeatPasswordValid;
    }

    return this.sharedAuth.isEmailValid;
  }

  get isSubmitButtonDisabled() {
    return (
      !this.isSsoEnforced &&
      (this.isRequestRunning || !(this.sharedAuth.password && this.repeatPassword))
    );
  }

  @action
  setRepeatPassword(event: Event) {
    this.repeatPassword = (event.target as HTMLInputElement).value;
  }

  @action
  setNewPasswordValidState(isValid: boolean) {
    this.isNewPasswordValid = isValid;
  }

  @action
  submitForm() {
    if (!this.isFormValid) {
      return;
    }

    this.sharedAuth.showError = false;
    this.sharedAuth.emailInputHasErrors = false;

    if (this.sharedAuth.password) {
      this.signUpWithPassword();
    }

    if (this.isSsoEnforced && this.sharedAuth.ssoLoginOptions[0]) {
      this.sharedAuth.ssoLoginOptions[0].onClickAction();
    }
  }

  @action
  submitFormWithEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.submitForm();
    }
  }

  @action
  reset() {
    this.sharedAuth.reset();
    this.repeatPassword = '';
  }
}
